import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '@/views/login.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      name: '首页'
    }

  },

  // 个人中心
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/mine.vue'),
    meta: {
      name: '个人中心'
    }
  },


  // 职位
  {
    path: '/position',
    name: 'position',
    component: () => import('@/views/position.vue'),
    meta: {
      name: '职位'
    }
  },
  // 职位详情
  {
    path: '/positionDetails',
    name: 'positionDetails',
    component: () => import('@/views/positionDetails.vue'),
    meta: {
      name: '职位详情'
    }
  },
  // 企业详情
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('@/views/enterprise.vue'),
    meta: {
      name: '企业详情'
    }
  },

  // 在线演员模卡
  {
    path: '/OnlineResume',
    name: 'OnlineResume',
    component: () => import('@/views/OnlineResume.vue'),
    meta: {
      name: '在线演员模卡'
    }
  },
  // 感兴趣  关注的公司什么的
  {
    path: '/record',
    name: 'record',
    component: () => import('@/views/record.vue'),
    meta: {
      name: '感兴趣'
    }
  },
  {
    path: '/accountOptions',
    name: 'accountOptions',
    component: () => import('@/views/accountOptions.vue'),
    meta: {
      name: '账号操作'
    }
  },
  {
    path: '/PackageBuy',
    name: 'PackageBuy',
    component: () => import('@/views/PackageBuy.vue'),
    meta: {
      name: '套餐购买'
    }
  },
  // staffDetault
  {
    path: '/staffDetault',
    name: 'staffDetault',
    component: () => import('@/views/staffDetault.vue'),
    meta: {
      name: '人员详情'
    }
  },
  // 公司信息更改
  {
    path: '/companyInfo',
    name: 'companyInfo',
    component: () => import('@/views/companyInfo.vue'),
    meta: {
      name: '公司信息更改'
    }
  },
  // 发布职位
  {
    path: '/PostPosition',
    name: 'PostPosition',
    component: () => import('@/views/PostPosition.vue'),
    meta: {
      name: '发布职位'
    }
  },
  // 添加剧组
  {
    path: '/addGroup',
    name: 'addGroup',
    component: () => import('@/views/addGroup.vue'),
    meta: {
      name: '添加剧组'
    }
  },
  // 角色管理
  {
    path: '/positionManage',
    name: 'positionManage',
    component: () => import('@/views/positionManage.vue'),
    meta: {
      name: '角色管理'
    }
  },
  {
    path: '/accountManage',
    name: 'accountManage',
    component: () => import('@/views/accountManage.vue'),
    meta: {
      name: '账号管理'
    }
  },
  {
    path: '/CrewManagge',
    name: 'CrewManagge',
    component: () => import('@/views/CrewManagge.vue'),
    meta: {
      name: '剧组管理'
    }
  },
  {
    path: '/notesDetials',
    name: 'notesDetials',
    component: () => import('@/views/notesDetials.vue'),
    meta: {
      name: '演员模卡详情'
    }
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/chat.vue'),
    meta: {
      name: '聊天沟通'
    }
  },
  {
    path: '/crewList',
    name: 'crewList',
    component: () => import('@/views/crewList.vue'),
    meta: {
      name: '剧组列表'
    }
  },
  {
    path: '/crewDetails',
    name: 'crewDetails',
    component: () => import('@/views/crewDetails.vue'),
    meta: {
      name: '剧组详情'
    }
  },
  // 查看的全部剧组
  {
    path: '/Allcrew',
    name: 'Allcrew',
    component: () => import('@/views/Allcrew.vue'),
    meta: {
      name: '公司下面的全部剧组'
    }
  },

  // 待试戏
  {
    path: '/walkThrough',
    name: 'walkThrough',
    component: () => import('@/views/walkThrough.vue'),
    meta: {
      name: '待试戏'
    }
  },






  // // 测试页面
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('@/views/test.vue'),
  //   meta: {
  //     name: '担保详情'
  //   }
  // },


]

const router = new VueRouter({
  history: 'hash', // 使用 hash 模式
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终返回 { x: 0, y: 0 }
    return { x: 0, y: 0 };
  }

})

export default router
